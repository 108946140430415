<template>
  <!-- 菜单添加页面 -->
  <moe-page title="物流公司添加">
    <moe-form ref="expressForm" :model="express" :rules="expressRules" :showBack="false" :showClose="true">
      <el-form-item label="物流公司图片" prop="coverUrl">
        <moe-upload-file
          v-model="express.coverUrl"
          ossKey="SHOP_MEDIA"
          upload-text="上传图片"
          :default-file-list="coverList"
          @change="$refs.expressForm.validateField('coverUrl')" />
      </el-form-item>
      <el-form-item label="物流公司名称" prop="name">
        <el-input v-model.trim="express.name" placeholder="请输入物流公司名称" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="物流公司编码" prop="com">
        <el-input v-model.trim="express.com" placeholder="请输入物流编码,严格遵循快递公司编码表" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="微信快递公司编码" prop="wxCom">
        <el-input v-model.trim="express.wxCom" placeholder="请输入微信快递公司编码,严格遵循微信快递公司编码表" maxlength="50" clearable />
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="expressLoad"
          @click="expressInfoSubmit">
          {{ expressLoad ? '保存中' : '保存' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: ' ExpressAdd',
  data() {
    const express = {
      name: '',
      com: '',
      wxCom: '',
      coverUrl:'',
    };

    // 菜单数据校验
    const verify = this.$moe_verify.verifyForm;
    const expressRules = {
      coverUrl: verify.isEmpty('请上传物流公司图片'),
      name: verify.isEmpty('请输入物流公司名称'),
      com: verify.isEmpty('请输入物流公司编码'),
      wxCom: verify.isEmpty('请输入微信快递公司编码'),
    };

    return {
      express, //菜单信息
      expressRules, //菜单数据校验
      expressLoad: false, //提交状态
      coverList: [], //图片列表
    };
  },
  created() {
    //获取广告信息
    if (this.$route.query.id) {
      this.getExpressDetail();
      this.$route.meta.title = '物流公司修改'
    } else {
      this.$route.meta.title = '物流公司新增'
    }
  },
  methods: {
    getExpressDetail() {
      // 获取物流公司ID
      const id = this.$route.query.id;

      // 查看广告详情
      this.$moe_api.GENERAL_API.queryShopExpressById({ id }).then((data) => {
        if (data.code == 200) {
          let a = data.result;
          this.express = {
            id: a.id,
            coverUrl: this.$moe_yunHelper.formatterImageUrl(a.coverUrl), //图片
            name: a.name, //名字
            com: a.com, //编码
            wxCom: a.wxCom,
          };
          if (a.coverUrl) {
            this.coverList = [
              {
                url: a.coverUrl,
                tempFilePath: this.$moe_yunHelper.getTempFilePath(a.coverUrl),
              }
            ]
          }

        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    expressInfoSubmit() {
      this.$refs['expressForm'].validate(() => {
        this.expressLoad = true;
        if (this.express.id) {
          this.$moe_api.GENERAL_API.updateShopExpress(this.express)
            .then((data) => {
              if (data.code == 200) {
                this.$moe_msg.success('修改成功', {
                  completionHandler: () => {
                    this.$moe_coordinator.navigateBack();
                  }
                });
              } else {
                this.$moe_msg.error(data.message);
              }
            })
            .finally(() => {
              this.expressLoad = false;
            });
        } else {
          this.$moe_api.GENERAL_API.expressAdd(this.express)
            .then((data) => {
              if (data.code == 200) {
                this.$moe_msg.success('添加成功', {
                  completionHandler: () => {
                    this.$moe_coordinator.navigateBack();
                  }
                });
              } else {
                this.$moe_msg.error(data.message);
              }
            })
            .finally(() => {
              this.expressLoad = false;
            });
        }
      })
    },
  },
};
</script>
